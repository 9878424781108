export default [
  {
    type: 'textarea',
    width: 'full',
    block: 'description-before',
    model: 'description',
    title: 'Описание нарушения'
  },
  {
    type: 'input',
    width: 'full',
    block: 'description-before',
    model: 'normative_doc',
    title: 'Выявленные причины ДТП'
  },
  {
    type: 'datetime',
    width: 'half',
    format: 'dd.MM.yyyy HH:mm',
    block: 'description-after',
    model: 'date_end',
    title: 'Срок устранения'
  },
  {
    type: 'input',
    model: 'name',
    width: 10,
    block: 'corrective-action',
    clearable: true,
    title: 'Описание мероприятия'
  },
  {
    type: 'checkbox',
    model: 'eliminated',
    width: 2,
    block: 'corrective-action',
    title: 'Устранено'
  }
]
