<template>
  <div class="accident-description">
    <attributes-list
      :model="model.filter(s => s.block === 'description-before')"
      :source="source.trafficaccident"
      :related="related"
      is-editing />
    <r-modal-card-block
      icon="ruler"
      title="Корректирующие мероприятия"
      transparent
      main
      action-name="Добавить"
      action-icon="add-plus"
      @action="addCorrectiveAction">
      <r-modal-card-block
        v-for="(action, i) in correctiveActions"
        :key="i"
        :title="`Мероприятие ${i + 1}`"
        delete-action
        @action="addCorrectiveAction">
        <div slot="title-button">
          <r-delete-button
            simple
            tiny
            @delete="deleteCorrectiveAction(action)" />
        </div>
        <attributes-list
          :model="model.filter(s => s.block === 'corrective-action')"
          :source="action"
          :related="related"
          is-editing />
      </r-modal-card-block>
    </r-modal-card-block>
    <attributes-list
      :model="model.filter(s => s.block === 'description-after')"
      :source="source.trafficaccident"
      :related="related"
      is-editing />
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import rModalCardBlock from '@/components/globals/r-modal-elements/r-modal-card-block'
import model from '../config/corrective-action-model'

export default {
  components: {
    attributesList,
    rModalCardBlock
  },
  props: {
    related: {
      type: Object,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      model
    }
  },
  computed: {
    correctiveActions () {
      return (
        this.source.trafficaccident?.corrective_actions?.filter(
          i => i.disabled !== true
        ) || []
      )
    }
  },
  methods: {
    addCorrectiveAction () {
      const template = {
        trafficaccident_id: this.source.trafficaccident.id,
        disabled: false,
        temp_id: Date.now()
      }

      const blank = this.model.reduce((a, c) => {
        if (c.model) {
          a[c.model] = null
        }
        return a
      }, template)

      this.source.trafficaccident.corrective_actions.push(blank)
    },
    deleteCorrectiveAction ({ id, temp_id }) {
      const intruder = this.source.trafficaccident.corrective_actions.find(i =>
        temp_id ? i.temp_id === temp_id : i.id === id
      )
      intruder.disabled = true
    }
  }
}
</script>

<style lang="scss">
.accident-description {
  display: grid;
  grid-gap: 1rem;
}
</style>
