export default [
  {
    type: 'checkbox',
    model: 'is_intruder',
    column: true,
    width: 4,
    title: 'Нарушитель'
  },
  {
    type: 'select',
    select: 'contractors',
    model: 'contractor_id',
    filterable: true,
    title: 'Подрядчик'
  },
  {
    type: 'select',
    select: 'contractors',
    model: 'subcontractor_id',
    filterable: true,
    title: 'Субподрядчик'
  },
  {
    type: 'select',
    select: 'contractors',
    model: 'subsubcontractor_id',
    filterable: true,
    title: 'Субсубподрядчик'
  },
  {
    divider: true
  },
  {
    type: 'input',
    model: 'name',
    width: 8,
    title: 'ФИО'
  },
  {
    type: 'date',
    model: 'birthday',
    format: 'dd.MM.yyyy',
    width: 4,
    title: 'Дата рождения'
  },
  {
    type: 'date',
    model: 'experience',
    format: 'dd.MM.yyyy',
    width: 12,
    title: 'Стаж'
  },
  {
    type: 'input',
    model: 'position',
    title: 'Должность'
  },
  {
    type: 'select',
    select: 'contractors',
    model: 'organization_id',
    filterable: true,
    title: 'Организация'
  },
  {
    type: 'select',
    select: 'three_vio',
    model: 'three_vio_id',
    title: 'Правило трёх нарушений'
  },
  {
    type: 'select',
    select: 'vehicles',
    model: 'vehicle_id',
    filterable: true,
    label: 'reg_number',
    block: 'vehicle',
    title: 'ГРЗ'
  },
  {
    model: 'model',
    read_only: true,
    adjacent: 'vehicle_id',
    select: 'vehicles',
    width: 'half',
    block: 'vehicle',
    title: 'Модель'
  },
  {
    model: 'vehicle_type',
    type: 'select',
    read_only: true,
    width: 'half',
    adjacent: 'vehicle_id',
    block: 'vehicle',
    select: 'vehicles',
    title: 'Вид ТС'
  },
  {
    model: 'vehicle_type',
    type: 'select',
    read_only: true,
    width: 'half',
    adjacent: 'vehicle_id',
    block: 'vehicle',
    select: 'vehicles',
    reflection: {
      related: 'vehicleTypes',
      field: 'vehicle_type_id',
      attr: 'vehicle_type_group'
    },
    title: 'Тип ТС'
  },
  {
    type: 'checkbox',
    model: 'vehicle_is_removed',
    column: true,
    block: 'vehicle',
    title: 'Транспортное средство удалено с проекта'
  },
  {
    type: 'checkbox',
    model: 'vehicle_is_damaged',
    column: true,
    block: 'vehicle',
    title: 'Повреждено'
  }
]
